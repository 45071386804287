<template>
    <div class="pageWrapper">
        <!--div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back.svg" />
                    </router-link>
                </div> 
                
                <div class="pageTitle">
                    <h1>{{pagetitle}}</h1>
                </div>
                <p>Traworld is a group project while I am handle on the full interface design of the merchant app based on the requirement from client and business analysit. The branding color had been set by other design and I am expand the interface based on available UI Kits.</p>

                <p>The merchant can create and modify their product and services through merchant website. However, the main features in the app is to check & confirm bookings and view reporting. Thus, I have come out with the interface to simplify the filter and sort features for booking and report search.</p>
                <ul class="generalUl">
                    <li>
                        <label>Involved in</label>
                        <p>UI & UX Design</p>
                    </li>
                </ul>

                <div class="action">
                    <small>Now Available in</small><br>
                    <a class="imgURL" href="https://apps.apple.com/my/app/traworld-merchant/id1541990375" target="_blank">
                        <img src="@/assets/images/works/traworld/app_store_badge.png">
                    </a>
                    <a class="imgURL" href="https://play.google.com/store/apps/details?id=com.traworld.merchant" target="_blank">
                        <img src="@/assets/images/works/traworld/google_play_badge.png">
                    </a>
                    

                    <p style="line-height:1.2; margin-top: 0;"><small>However, the latest UI was not fully updated with the version in app store and play store due to some issues.</small></p>
                </div>

            </div>
        </div-->

       
        <!--div class="pageRight">
            <div class="inner">
                
                <section>
                    <div class="styledHeading mb-3">
                        <h1>UI Kits</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/traworld/uikits.png">
                </section>


                <section class="traworldContainer">
                    <div class="styledHeading mb-3">
                        <h1>Interface Design</h1>
                    </div>
                    <div class="screenshots">
                        <img v-preview:scope-a class="mobile" src="@/assets/images/works/traworld/screenshot_1.png">
                        <img v-preview:scope-a class="mobile" src="@/assets/images/works/traworld/screenshot_2.png">
                        <img v-preview:scope-a class="mobile" src="@/assets/images/works/traworld/screenshot_3.png">
                    </div>
                </section>
                

                <section>
                    <div class="styledHeading mb-3">
                        <h1>User Flow</h1>
                    </div>
                    <img v-preview:scope-a src="@/assets/images/works/traworld/userflow.png">
                </section>


            </div>
        </div-->
       
        <div class="workHeader traworld">
            <div class="container">
                <div class="pageAction">
                    <router-link class="back" to="/">
                        <img src="@/assets/images/back_white.svg" />
                    </router-link>
                </div> 

                <img src="@/assets/images/works/traworld/traworld_header.png" class="headerImg">

                <div class="headerContent">
                    <h1>{{pagetitle}}</h1>
                    <ul>
                        <li>UI UX Design</li>
                        <li>2020</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="workContent">
            <section>
                <div class="container">

                    <div class="flexContainer">
                        <div class="col-8">
                            <div class="liteHeading">Overview</div>
                            <p>Traworld is a e-commerce platform specialized in travel sector and it’s a group project. I have worked together with some other design to make sure the UI design theme is consistent in multiple platform like e-commerce app, e-commerce website, merchant site and merchant app. We keep the app consistent by using the same UI Kits which come with all the designed components.</p>
                        </div>
                        <div class="col-4 text-right">
                            <div class="liteHeading">Task</div>
                            <ul>
                                <li>UI / UX Design</li>
                                <li>User Research</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="sectionGrey">
                <div class="container">

                    <div class="styledHeading">
                        <h2>Traworld Merchant App</h2>
                    </div>
                    <p>I am being assigned to the merchant site and merchant app. The merchant app is targeting to the e-commerce app merchant with the main function to monitor sales and approve booking. The merchant want it to be quick and well-organized sales record with some filters.</p>
                    
                    <div class="liteHeading text-danger">Pain Points</div>

                    <ul>
                        <li>Difficult to check and manage whenever away from laptop</li>
                        <li>Static table report in website</li>
                    </ul>
                    
                    <Flicking :options="{ align: 'prev' }" class="mt-5">
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/traworld/screenshot_1.png">
                        </div>
                        <div style="min-width:50%">
                            <img v-preview:scope-a src="@/assets/images/works/traworld/screenshot_2.png" >
                        </div>
                        <div style="min-width:50%">
                        <img v-preview:scope-a src="@/assets/images/works/traworld/screenshot_3.png">
                        </div>
                    </Flicking>

                    <div class="spacing mb-1"></div>

                    <div class="styledHeading mb-3">
                        <h4>User Flow</h4>
                    </div>
                    <img src="@/assets/images/works/traworld/userflow_1.png">

                    <div class="spacing"></div>


                    <div class="flexContainer">
                        <div class="col-6">
                            <div class="styledHeading mb-4">
                                <h4>Color Palatte</h4>
                            </div>
                            <img src="@/assets/images/works/traworld/color_palatte.png" class="colorpalatte">
                        </div>
                        <div class="col-6">
                            <div class="styledHeading mb-4">
                                <h4>Typography</h4>
                            </div>
                            <img src="@/assets/images/works/traworld/typo.png"  class="typo">
                        </div>
                    </div>

                </div>
            </section>

        </div>

        <div class="footer">
            <div class="container text-center">
                
                <div class="styledHeading">
                    <h3>Let's connect</h3>
                </div>
                <p>Get in touch for opportunities or just to say hi! 👋</p>

                <div class="action">
                    <a href="https://www.linkedin.com/in/kinhang-aw-455185181" target="_blank"><img src="@/assets/images/icon_linkedin.svg"></a>
                    <!--a href=""><img src="@/assets/images/icon_envelope.svg"></a-->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import PhotoSwipe from 'photoswipe/dist/photoswipe'
    import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
    import 'photoswipe/dist/photoswipe.css'
    import 'photoswipe/dist/default-skin/default-skin.css'
    import createPreviewDirective from "vue-photoswipe-directive";
    import { Flicking } from "@egjs/vue-flicking";
    
    export default {
        components: {
            Flicking: Flicking
        },
        directives: {
            preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI)
        },
        name: 'traworld',
        data () {
            return {
                pagetitle: 'Traworld',
            }
        }
    }
</script>

<style>
    .flicking-camera{
        display: flex;
    }
    .flicking-camera > div{
        margin: 0 .5rem;
        cursor: pointer;
    }
</style>